/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // Get .container like padding on full-width areas 
        var pageContent1 = $('.top-section .row .copy-container');
        var pageContent2 = $('.footer-callout .row .copy-container');
        // var homeIntro = $('.map-wrapper')
        var point = $('.brand');
        var offset = point.offset().left + parseInt(point.css('padding-left'));
        pageContent1.css({
            'padding-right': offset,
        });
        pageContent2.css({
            'padding-left': offset,
        });
        $(window).resize(function() {
            var offset = point.offset().left + parseInt(point.css('padding-left'));
            pageContent1.css('padding-right', offset);
            pageContent2.css('padding-left', offset);
        });

        //Footer Scroll to top
        $("a.scrollToTop").click(function(e) {
          $("html, body").animate({ scrollTop: 0 }, "slow");
          e.preventDefault();
        });

        // Bootstrap dropdowns on hover
        $('ul.nav li.dropdown').hover(function() {
          $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeIn(500);
        }, function() {
          $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeOut(500);
        });

        // Equal height columns for banner/copy areas where flexbox isn't supported
        if ( $('html').hasClass('no-flexbox') ){
          $('.row-eq-height').each(function(){
            height = $(this).find('.copy-container').height();
            $(this).find('.image-container').height(height);
          });
        }

        window.fbAsyncInit = function() {
          FB.init({
            appId      : '501974306632518',
            xfbml      : true,
            version    : 'v2.4'
          });
        };

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        loadSocialFeed();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'tobacco_free_social_feed' : {
        init: function(){
            var p = 0;
          loadSocialFeed(true);

            $(window).on('scroll', function(){
                if (!$('.loading').is(":visible")){
                    if ($('body').innerHeight() - $(this).scrollTop() <= $('.social-container').innerHeight()){
                        p = p + 5;
                        loadSocialFeed(true, p);
                    }
                }
            });

        },
        finalize: function(){

        }
    },
    // Map Page
    'smoke_free_map': {
      init: function(){
        var markers = [];
        var map = null;
        var base_zoom = 13;

        // jshint latedef:nofunc
        var callback = function(targetMap){
          return function(data){
            var items = $(data);

            clearMarkers();
            $.each(items, function(index, data){
              var location = {
                lat: parseFloat($(data).attr('data-lat')),
                lng: parseFloat($(data).attr('data-long'))
              };
              locTitle = $(data).find('h3').html();

              var color = function(){
                var colors = [
                  '#d25c35',
                  '#5a2929',
                  '#11360D',
                  '#B59E19',
                  '#6656ff',
                  '#18143d',
                  '#382BB5'
                ];

                return colors[Math.floor(Math.random() * colors.length)];
              };

              var icon = function($cat){
                switch($cat){
                  case 'Smoke-Free Apartments':
                    return 'fa-building';
                  case 'Smoke-Free Colleges':
                    return 'fa-institution';
                  case 'Tobacco-Free Colleges':
                    return 'fa-ban';
                  case 'Tobacco-Free Outdoors':
                    return 'fa-tree';
                }
              };

              var placement = index;
              var title = $(data).find('h3').text();
              var cat = $(data).attr('data-category');
              addMarkerWithTimeout(targetMap, placement, location, title, icon(cat));

            });

            $('#item-container').append(items);
          };
        };

        function initMap(){
          map = new google.maps.Map(document.getElementById('map-canvas'), {
            center: new google.maps.LatLng(42.9172389, -78.884702),
            zoom: base_zoom,
            mapTypeId: google.maps.MapTypeId.ROADMAP
          });

          $.get(window.location.origin+'/social.php?type=members', callback(map));

          jQuery(window).load(function(){
            console.log('the window has loaded');
            console.log(jQuery('.marker').length);
            jQuery('.marker').bind('click', function(){
                var ind = jQuery(this).attr('data-index');
                console.log(markers[ind].position);
                jQuery('#item-container .item.active').removeClass('active');
                jQuery('#item-container .item').eq(ind).addClass('active');

                console.log(ind);
                
              });
          });
        }

        function addMarkerWithTimeout(targetMap, placement, position, title, icon) {
            markers.push(new RichMarker({
              position: new google.maps.LatLng(position.lat, position.lng),
              map: targetMap,
              title: title,
              flat: true,
              anchor: RichMarkerPosition.MIDDLE,
              content: "<div class=\"marker\" data-index=\"" + placement + "\" ><p class=\"marker-title\">" + title + "</p><i class='map-icon fa "+icon+"'></i></div>",
              animation: google.maps.Animation.DROP
            }));
          console.log(placement);
        }

        function clearMarkers() {
          for (var i = 0; i < markers.length; i++){
            markers[i].setMap(null);
          }

          markers = [];
        }

        google.maps.event.addDomListener(window, 'load', initMap());

        var getActiveIndex = function(){
          var items = $('.item');
          var active = null;
          $.each(items, function(i, v){
            if ($(v).hasClass('active') === true){
              active = i;
            }
          });

          return {
            active: active <= items.length - 1 ? active : null,
            item: items[active]
          };
        };

        function centerMap(i, reverse){
          var pos;

          if (reverse){
            pos = markers[i.active-1].position;
          } else {
            pos = markers[i.active+1].position;
          }

          var ltLn = {
            lat : pos.lat(),
            lng : pos.lng()
          };

          if (!isNaN(ltLn.lat) && !isNaN(ltLn.lng)){
            map.setZoom(base_zoom);
            setTimeout(function(){
                setTimeout(function(){
                    map.panTo(ltLn);
                    setTimeout(function() {
                        map.setZoom(base_zoom + 1);
                    }, 400);
                }, 200);
            }, 200);
          }
        }

        $('#member-forward').click(function(){
          var i = getActiveIndex();
          var items = $('.item');

          if (i.active !== null && items.length - 1 > i.active){
            var next = $(i.item).next('.item');
            $(i.item).removeClass('active');
            $(next).addClass('active');
            centerMap(i, false);
          }
        });


        $('#member-back').click(function(){
          var i = getActiveIndex();

          if (i.active !== 0 && i.active !== null){
            var prev = $(i.item).prev('.item');
            $(i.item).removeClass('active');
            $(prev).addClass('active');
            centerMap(i, true);
          }
        });
      },
      finalize: function() {
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  // jshint latedef:nofunc
  function loadSocialFeed(paging, p) {
    var loadingClass = '.loading',
        socialClass = '.social-column';

    var columns = $('.social-column');

    var finish = function(id, i){
      return function(data, textStatus, jqXHR){
        var itemContainer = $(columns[i]).find('.social-items');
        var jData = $(data);

        if ($(loadingClass).is(":visible")){
          $(loadingClass).animate({opacity: 0}, 'slow').hide();
        }

        $(socialClass).show().animate({ opacity: 1}, 'slow');

        $.each(jData, function(index, value){
          $(value).css('opacity', '0');
          $(value).css('left', '-10000px');

          $(itemContainer).append(value);
          $(value).animate({ opacity: 1 }, { duration: 1500, queue: false })
              .animate({ left: 0}, { duration: 800, queue: false });

        });

        if (id === 'facebook'){
          (function(d, s, id){
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {return;}
            js = d.createElement(s); js.id = id;
            js.async=true;

            js.src = "//connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
          }(document, 'script', 'facebook-jssdk'));
        }

      };
    };

    var start = function(){
        if (typeof p === 'undefined'){
            $(socialClass).hide();
        }
      $(loadingClass).css('opacity', '1');

      var i = 0;

      for(i; i < columns.length ; i++){
        var id = $(columns[i]).prop('id');
        if (typeof paging !== 'undefined'){
          $.get(window.location.origin+'/social.php?type='+id+'&limit=5&start_at='+p, finish(id, i));
        } else {
          $.get(window.location.origin+'/social.php?type='+id, finish(id, i));
        }
      }
    };

    start();
  }

})(jQuery); // Fully reference jQuery after this point.
